#MenuDropdown::after,
#MenuDropdown::before {
    display: none;
}

#MenuDropdown {
    background-color: rgba(0, 0, 0, 0);
    color: #6b7280;
    padding-bottom: 5;
    border-color: rgba(0, 0, 0, 0);
}

#spacer {
    height: 8rem;
}

#CardContainer {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#CardContainer::-webkit-scrollbar {
    display: none;
}